<template>
  <div class="box mt-4 intro-x">
    <DataTable
      showGridlines
      ref="dt"
      :value="invoicingList"
      class="p-datatable-sm text-xs p-datatable-customers"
      dataKey="id"
      :paginator="true"
      :rows="getPaginate.sizePaginate"
      :first="getPaginate.first"
      :lazy="true"
      :totalRecords="getPaginate.total"
      @page="onPage($event)"
      @sort="onSort"
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      :rowsPerPageOptions="[5,10,25,50,100,250,500]"
      currentPageReportTemplate="Mostrando registros del {first} al {last} de un total de {totalRecords} registros"
      responsiveLayout="scroll"
      breakpoint="960px"
    >
      <template #header>
        <FiltersInvoices v-model="filters" @search="onFilter" />
      </template>

      <!-- Columnas -->
      <Column field="id" header="#" headerStyle="width: 3rem" :sortable="true"></Column>
      <Column field="createdAt" header="Fecha Registrada" headerStyle="width: 10rem" :sortable="true">
        <template #body="{data}">
          {{ $h.formatDate(data.createdAt,'YYYY-MM-DD HH:mm:ss') }}
        </template>
      </Column>
      <Column field="documentNumber" header="Documento" headerStyle="width: 6rem" :sortable="true">
        <template #body="{data}">
          {{ data.documentTypeName }}
          <div>
            {{ data.documentNumber }}
          </div>
        </template>
      </Column>
      <Column field="providerName" header="Proveedor" :sortable="true"></Column>
      <Column field="receiverName" header="# Solicitante"></Column>
      <Column field="documentNumber" header="Fase - Estado" headerStyle="width: 8rem" >
          <template #body="{data}">
            <div >
              {{ data.stageName }}
              <div :style="data.stageStyle" class="customer-badge" >
                {{ data.stageStatus }}
              </div>
            </div>
          </template>
      </Column>
      <Column field="numPago" header="N° Pago" headerStyle="width: 8rem" >
      </Column>
      <Column :exportable="false" header="Acciones" headerStyle="text-align: center;" style="text-align: center; min-width:10rem">
        <template #body="{data}">
          <Button
            icon="pi pi-check-circle"
            class="p-button-rounded p-button-plain p-mr-2"
            v-tooltip.top="'Gestionar'"
            @click="openModalStage(data)"
            :disabled="!((permissionUser.includes(data.stage) || (permissionUser.includes('Area') && data.stage === 'Request') ) && ( data.stageAccountStatus!=='Aceptado'))"
          />
          <Button
            v-if="permissionUser.includes('Account')"
            class="p-button-rounded p-button-plain p-mr-2"
            icon="pi"
            v-tooltip.top="'Solicitar Autorización'"
            @click="sendAuth(data)"
            :disabled="!(data.stage === 'Account' && data.stageManagerStatus!=='Aceptado' && data.stageAccountStatus!=='Aceptado')"
          ><font-awesome-icon class="text-sm" icon="share-square"/></Button>
          <Button
            icon="pi pi-clock"
            class="p-button-rounded p-button-plain p-mr-2"
            v-tooltip.top="'Ver Seguimiento'"
            @click="openModalFollow(data)"
          />
          <Button
            icon="pi pi-file-pdf"
            class="p-button-rounded p-button-plain p- mr-2"
            v-tooltip.top="'Ver PDF'"
            @click="getImagePath({ picture : data.adjunct, title : data.documentTypeName + ' #' + data.documentNumber })"
          />
          <Button
            v-if="permissionUser.includes('Account') || permissionUser.includes('Register')  || permissionUser.includes('Manager')"
            icon="pi pi-file"
            class="p-button-rounded p-button-plain p-mr-2"
            v-tooltip.top="'Comprobante'"
            :disabled="!data.transIdDoc"
            @click="onViewVoucher(data.transIdDoc, data.id)"
          />
          <Button
            v-if="permissionUser.includes('Account') || permissionUser.includes('Manager')"
            icon="pi pi-file"
            class="p-button-rounded p-button-plain p-mr-2"
            :disabled="!data.transIdPago"
            v-tooltip.top="'Pago'"
            @click="onViewVoucher(data.transIdPago, data.id)"
          />
        </template>
      </Column>
    </DataTable>
  </div>

  <Dialog v-model:visible="displayModalManager"
          :breakpoints="{'960px': '95vw'}"
          :style="{width: '25vw'}">
    <template #header>
      <h3>Gestionar Fase</h3>
    </template>
    <form action="" @submit.prevent="onSubmit" >
      <div class="p-3">
        <div class="p-field p-col-12 p-md-12">
          <label for="supplierTypes">Estado:</label>
          <Dropdown
            id="supplierTypes"
            v-model="modelo.status"
            :options="statusType"
            optionLabel="name"
            optionValue="id"
            placeholder="Seleccione una opción"
            :filter="false"
            scrollHeight="auto"
            filterPlaceholder="Buscar por opción  "
            class="h-10 border mt-1 rounded w-full"
          />
          <MessageError :text="errors.status" />
        </div>
        <div class="p-field p-col-12 p-md-12">
          <label for="note">Descripción</label>
          <Textarea
            v-model="modelo.note"
            id="note"
            rows="2"
            class="p-md-12"
          />
          <MessageError :text="errors.note" />
        </div>
      </div>
    </form>
    <template #footer>
      <Button label="Aceptar" class="p-button-success" @click="onSubmit" />
      <Button label="Cancelar" class="p-button-secondary" @click="closeModalStage" />
    </template>
  </Dialog>

  <!--- Modal Form crate invoice in SAP --->
  <Dialog
    header="Factura de Proveedores"
    v-model:visible="displayRegisterDocument"
    :breakpoints="{'990px': '95vw'}"
    :style="{width: '90vw', 'z-index': 500}"
    :modal="true"
  >
    <modal-form :itemSelected="itemSelect" @onCompleSubmit="CompleSubmit" @onCloseForm="displayRegisterDocument = false"></modal-form>
  </Dialog>

  <!--- Modal Follow-up register --->
  <Dialog v-model:visible="displayModalFollow"
          :breakpoints="{'960px': '95vw'}"
          :style="{width: '30vw'}">
    <div>
      <div class="card">
        <Timeline :value="infLogs"  class="customized-timeline">
          <template #opposite="slotProps">
            <div class="row">
              <span style="text-align: left; color: #1C3FAA; opacity: 1; font: normal normal 600 14px/18px Segoe UI;">{{
                  stagesName[slotProps.item.stage]
                }} </span>
            </div>
            <div class="row">
              <small class="p-text-secondary">
                {{ $h.formatDate(slotProps.item.createdAt,'YYYY-MM-DD HH:mm:ss') }}</small>
            </div>
          </template>
          <template #content="slotProps">
            <small class="p-text-secondary">{{ slotProps.item.status }} por: {{slotProps.item.full_name}}</small>
          </template>
        </Timeline>
      </div>

    </div>

  </Dialog>
</template>

<script>
import { onMounted, ref, computed, watch, defineAsyncComponent } from 'vue'
// import { useToast } from 'primevue/usetoast'
import Swal from 'sweetalert2'
import configShops from '../../../../../../../../config/shops'
import getLogService from '../../../../../services/getLogService'
import updateStageInvoicingService from '../../../../../services/updateStageInvoicingService'
import getInvoiceList from '../../../../../services/getInvoiceListService'
import { useStore } from 'vuex'
import { object, string } from 'yup'
import { useField, useForm } from 'vee-validate'
import { messageConfirmSave } from '../../../../../../../../libs/mensajes'
import { useRoute, useRouter } from 'vue-router'
export default {
  name: 'listFinancialProduct',
      components: {
        modalForm: defineAsyncComponent(() => import('../modalForm')),
    FiltersInvoices: defineAsyncComponent(() => import('../filters'))
  },
  props: {
    permissionUser: { type: Array }
  },
  emits: ['fetchListInvoicing'],
  setup (props, context) {
    const infLogs = ref([])
    // const toast = useToast()
    const invoicingList = ref()
    const store = useStore()
    const paginate = ref({
      total: 0,
      rows: 0,
      page: 0,
      first: 0
    })
    const commonPeople = store.getters['auth/getPeople']
    const dataSend = ref({
      status: '',
      note: '',
      stage: '',
      DocNum: '',
      DocEntry: '',
      invoiceId: 0,
      documentType: 0,
      providerCode: '',
      documentNumber: '',
      userId: commonPeople.id
    })

    const router = useRouter()
    const route = useRoute()
    const itemSelect = ref({})
    const totalRecords = ref(0)
    const loading = ref(false)
    const displayModalManager = ref(false)
    const displayRegisterDocument = ref(false)
    const displayModalFollow = ref(false)
    const statusType = ref([
      { id: 'Aceptado', name: 'Aceptado' },
      { id: 'Devuelto', name: 'Devolver' }
      ]
    )

    const stagesName = ref({
      Reception: 'Recepción',
      Request: 'Solicitante',
      Area: 'Dir. Area',
      Register: 'Contabilidad',
      Account: 'Dir. Contabilidad',
      Manager: 'Autorización'
    })
    // const currentPage = ref(1)
    // const sortAssign = ref('createdAt,ASC')
    const dt = ref()
    const filters = ref({
      startDate: '',
      endDate: '',
      documentType: '',
      documentNumber: '',
      stageCurrent: '',
      status: '',
      numPago: ''
    })

    /* */
    const fetchInvoicingList = () => {
      const stage = props.permissionUser.join(',')
      if (props.permissionUser.length === 0) {
        return
      }
      // Validar si es admin o tiene todos los permisos envie 0 en el userId Para que traiga todos los registros
      const valueUserId = (props.permissionUser.some(element => ['Register', 'Account', 'Manager', 'Reception'].includes(element))) ? 0 : commonPeople.id
      routerPush({ stage: stage, userId: valueUserId })
      const queryParams = route.query
      getInvoiceList(queryParams).then(({ data }) => {
        paginate.value.total = data.total
        invoicingList.value = data.data

        invoicingList.value.forEach((invoicing) => {
          if (invoicing.stageReceptionStatus === 'Pendiente' || invoicing.stageReceptionStatus === 'Devuelto') {
            invoicing.stage = 'Reception'
            invoicing.stageName = 'Recepción'
            invoicing.stageStatus = invoicing.stageReceptionStatus
          }
          if (invoicing.stageRequestStatus === 'Pendiente' || invoicing.stageRequestStatus === 'Devuelto') {
            invoicing.stage = 'Request'
            invoicing.stageName = 'Solicitante'
            invoicing.stageStatus = invoicing.stageRequestStatus
          }
          if (invoicing.stageAreaStatus === 'Pendiente' || invoicing.stageAreaStatus === 'Devuelto') {
            invoicing.stage = 'Area'
            invoicing.stageName = 'Dir. Area'
            invoicing.stageStatus = invoicing.stageAreaStatus
          }
          if (invoicing.stageRegisterStatus === 'Pendiente' || invoicing.stageRegisterStatus === 'Devuelto') {
            invoicing.stage = 'Register'
            invoicing.stageName = 'Contabilidad'
            invoicing.stageStatus = invoicing.stageRegisterStatus
          }
          if (invoicing.stageManagerStatus === 'Pendiente') {
            invoicing.stage = 'Manager'
            invoicing.stageName = 'Autorización'
            invoicing.stageStatus = invoicing.stageManagerStatus
          }
          if (invoicing.stageAccountStatus === 'Pendiente' || invoicing.stageAccountStatus === 'Devuelto' || invoicing.stageAccountStatus === 'Aceptado') {
            invoicing.stage = 'Account'
            invoicing.stageName = 'Dir. Contabilidad'
            invoicing.stageStatus = invoicing.stageAccountStatus
          }
          if (invoicing.stageStatus === 'Pendiente') {
            invoicing.stageStyle = 'background: #FDFFD1 0% 0% no-repeat padding-box;border-radius: 3px;opacity: 1'
          }
          if (invoicing.stageStatus === 'Aceptado') {
            invoicing.stageStyle = 'background: #C1FCDB 0% 0% no-repeat padding-box;border-radius: 3px;opacity: 1'
          }
          if (invoicing.stageStatus === 'Devuelto') {
            invoicing.stageStyle = 'background: #DCF8FE 0% 0% no-repeat padding-box; border-radius: 3px; opacity: 1;'
          }
        })
      })
    }

    /**/
    const onPage = (row) => {
      const { query } = route
      router.push({
        query: {
          ...query,
          page: row.page + 1,
          first: row.first,
          paginate: row.rows
        }
      })
    }

    const onSort = (event) => {
      routerPush({
        sortField: event.sortField,
        sortOrder: event.sortOrder
      })
      // fetchFinancialProducts(event, 'sort', filters.value)
    }

    /* Show the pdf in a modal */
    const getImagePath = ({ picture, title }) => {
      if (picture) {
        const bodyHtml = `
        <hr>
        <div class="row">
          <div class="col-lg-12" id="div_pdf">
            <embed src="${configShops.api + '/files/' + picture}" frameborder="0" width="100%" height="750rem">
          </div>
        </div>`

        Swal.fire({
          title: title,
          html: bodyHtml,
          type: 'success',
          showConfirmButton: false,
          showCancelButton: true,
          cancelButtonText: 'Cerrar',
          cancelButtonColor: '#f3565d',
          width: '120rem'
        })
      }
    }

    /* Close modal */
    const closeModalStage = () => {
      displayModalManager.value = false
    }

    /* Open modal and save the row selected  */
    const openModalStage = (data) => {
      handleReset()
      itemSelect.value = data
      dataSend.value.DocEntry = data.DocEntry
      dataSend.value.DocNum = data.DocNum
      dataSend.value.stage = data.stage
      dataSend.value.invoiceId = data.id
      dataSend.value.documentType = data.documentType
      dataSend.value.providerCode = data.providerCode
      dataSend.value.documentNumber = data.documentNumber
      displayModalManager.value = true
    }

    useField('status', null, { initialValue: null })
    useField('note', null, { initialValue: null })
    useField('stage', null, { initialValue: null })
    useField('userId', null, { initialValue: null })
    useField('invoiceId', null, { initialValue: null })

    const validationSchema = object({
      status: string().nullable().required().label('Estado'),
      note: string().nullable().when('status', (status) => {
        if (status === 'Devuelto') {
          return string().nullable().required().label('Descripción')
        }
      })
    })

    // Create a form context with the validation schema
    const { errors, handleSubmit, values: modelo, handleReset } = useForm({
      validationSchema
    })

    // Submit the send data
    const onSubmit = handleSubmit((values) => {
      if (dataSend.value.stage === 'Register' && values.status === 'Aceptado' && !displayRegisterDocument.value && dataSend.value.documentType === 2) {
        displayModalManager.value = false
        displayRegisterDocument.value = true
        return
      }
      displayRegisterDocument.value = false
      dataSend.value.status = values.status
      dataSend.value.note = values.note
      updateStageInvoicingService(dataSend.value).then((result) => {
        if (props.permissionUser.includes('Area') && dataSend.value.stage === 'Request') {
          dataSend.value.stage = 'Area'
          updateStageInvoicingService(dataSend.value).then((result) => {
            handleReset()
            closeModalStage()
            fetchInvoicingList()
          })
        } else {
          handleReset()
          closeModalStage()
          fetchInvoicingList()
        }
      })
    })

    const sendAuth = (data) => {
      itemSelect.value = data
      dataSend.value.stage = data.stage
      dataSend.value.invoiceId = data.id
      dataSend.value.status = 'Confirmar'
      messageConfirmSave('', 'Solicitar Autorización', 'warning', '¿Esta seguro de solcitar autorizacíon?').then(({ isConfirmed }) => {
        updateStageInvoicingService(dataSend.value).then((result) => {
          fetchInvoicingList()
        })
      })
    }

    /* Save the supplier invoice number returned by SAP  */
    const CompleSubmit = ({ DocNum, DocEntry }) => {
      dataSend.value.DocNum = DocNum
      dataSend.value.DocEntry = DocEntry
      onSubmit()
    }

    /* Open modal follow-up */
    const openModalFollow = (data) => {
      displayModalFollow.value = true
      fetchLog(data.id)
    }

    const fetchLog = (id) => {
      getLogService(id).then(({ data }) => {
        infLogs.value = data
      })
    }

    const routerPush = (obj = {}) => {
      const queryParams = route.query
      router.push({ query: { ...queryParams, ...obj } })
    }

    const onFilter = () => {
       console.log(filters.value)
       const createdAt = []
      if (![null, undefined, ''].includes(filters.value.startDate)) {
        createdAt.push(filters.value.startDate)
      }
      if (![null, undefined, ''].includes(filters.value.endDate)) {
        createdAt.push(filters.value.endDate + ' 23:59:59.000')
      }
      const cloneFilters = {}
      for (const [key, value] of Object.entries(filters.value)) {
        if (!['startDate', 'endDate'].includes(key) && ![null, undefined].includes(filters.value[key])) {
          cloneFilters[key] = value
        }
      }
      /* Esquema de validacion con sus reglas */
     routerPush({
        createdAt,
        ...cloneFilters
      })
    }

    const getPaginate = computed(() => {
      return {
        total: paginate.value.total,
        first: +route.query.first || 0,
        sizePaginate: +route.query.paginate || 10
      }
    })

    const onViewVoucher = (transid, id) => {
      window.open(`${process.env.VUE_APP_MICROSERVICE_API_SHOPS}/print-pdf-payment?transid=${transid}&id=${id}`, '_blank')
    }

    watch(() => route.query, () => {
      if (route.name === 'admin.administrativo.facturacion') {
        fetchInvoicingList()
      }
    })
    /* */
    onMounted(() => {
      fetchInvoicingList()
    })

    return {
      stagesName,
      modelo,
      errors,
      onSubmit,
      statusType,
      displayModalManager,
      displayModalFollow,
      displayRegisterDocument,
      dt,
      totalRecords,
      filters,
      loading,
      itemSelect,
      infLogs,
      getPaginate,
      invoicingList,
      openModalFollow,
      CompleSubmit,
      getImagePath,
      closeModalStage,
      openModalStage,
      onPage,
      onSort,
      onFilter,
      sendAuth,
      onViewVoucher
    }
  }
}
</script>

<style scoped>
.flex-row {
  display: flex;
  flex-direction: row;
}
.button-container {
  display: flex;
  flex-direction: row;
}
.button-container > * {
  margin-top: 15px;
  margin-right: 10px;

}
</style>
